import { memo } from 'react';
import { fill_horizontal_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import SymbolPlatformBase from '~/modules/symbolPlatform/base/SymbolPlatformBase';
import IntradayTrendChart from '~/modules/trendChart2';
import { store } from '../heineken_template/_private/store';
import TitleSymbolQuote from '~/modules/investment-consultant/symbolList/body/SymbolQuoteTitle';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { PositionContent } from './component/PositionContent';
import { AiFillCaretRight } from 'react-icons/ai';
import styled from '@emotion/styled';
import { useSnapshot } from 'valtio';
const symbol = ['TX-1', 'YM-1', 'NQ-1', 'ES-1', 'GC-1', 'CL-1', 'CDF-1'];
const watchListGroup = 'fubon_group_1';
export const Fubon_SidePane = memo(function Fubon_SidePane() {
    const charting = useSnapshot(store).charting;
    const theme = useThemeStore(t => t.theme);
    return (<styleds.continer className={theme}>
      <styleds.symbolQuoteContent className={theme}>
        <TitleSymbolQuote.Default symbol={charting.symbol}/>
      </styleds.symbolQuoteContent>

      <styleds.symbolTrendChartContent className={theme}>
        <IntradayTrendChart symbol={charting.symbol} ticksSize={14} ticksHeight={20} priceTicksMargin={2.5} priceTicksSize={14}/>
      </styleds.symbolTrendChartContent>
      <styleds.positionTitleContent className={theme}>
        <AiFillCaretRight />
        大週期趨勢指標
      </styleds.positionTitleContent>
      <PositionContent type={1}/>
      <styleds.positionTitleContent className={theme}>
        <AiFillCaretRight />
        小週期趨勢指標
      </styleds.positionTitleContent>
      <PositionContent type={2}/>
      <styleds.plateformContent>
        <SymbolPlatformBase.Display listTypeSwitch={false} symbol={symbol} watchListGroup={watchListGroup}/>
      </styleds.plateformContent>
    </styleds.continer>);
});
const styleds = {
    continer: styled.div `
    ${fill_vertical_cross_center};
    padding: 4px;
    gap: 4px;
    overflow-y: auto;
    overflow-x: hidden;
    &.dark {
      &::-webkit-scrollbar {
        width: 4px;
        background-color: #2f2f2f;
      }
      &::-webkit-scrollbar-thumb {
        width: 4px;
        border-radius: 5px;
        background-color: #989898;
      }
    }
    &.light {
      &::-webkit-scrollbar {
        width: 4px;
        background-color: #e9e9e9;
      }
      &::-webkit-scrollbar-thumb {
        width: 4px;
        border-radius: 5px;
        background-color: #b5b5b5;
      }
    }
  `,
    symbolQuoteContent: styled.div `
    width: 100%;
    height: 30px;
    padding: 4px;
    border-radius: 4px;
    &.dark {
      background-color: #202026;
    }
    &.light {
      background-color: #fafafa;
    }
    flex-shrink: 0;
  `,
    symbolTrendChartContent: styled.div `
    width: 100%;
    height: 164px;
    padding: 4px;
    border-radius: 4px;
    &.dark {
      background-color: #17171b;
    }
    &.light {
      background-color: #fafafa;
    }
    flex-shrink: 0;
  `,
    positionTitleContent: styled.div `
    ${fill_horizontal_all_center};
    width: 100%;
    height: 24px;
    border-radius: 4px;
    &.dark {
      background-color: #34343a;
    }
    &.light {
      background-color: #e9e9e9;
    }
    flex-shrink: 0;
  `,
    plateformContent: styled.div `
    width: 100%;
    height: 464px;
    flex-shrink: 0;
  `,
};
